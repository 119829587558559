import React from 'react';

const Pricing: React.FC = () => {
  return (
    <div>
      <h1>Pricing Page</h1>
    </div>
  );
};

export default Pricing;
