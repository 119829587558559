import React from 'react';

const Portfolio: React.FC = () => {
  return (
    <div>
      <h1>Portfolio Page</h1>
    </div>
  );
};

export default Portfolio;
